<template>
  <v-container tag="section" class="px-10 pb-10">
    <div>
      <div class="flex space-x-4 items-center mb-4"></div>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="name"
            :rules="[rules.required]"
            outlined
            prepend-inner-icon="mdi-account"
            color="#FBB040"
            label="Tên bác sỹ"
          />
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="phone"
            :rules="[rules.required]"
            outlined
            prepend-inner-icon="mdi-phone"
            color="#FBB040"
            label="Số điện thoại*"
            :maxLength="10"
          />
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="slotDuration"
            :rules="[rules.required]"
            type="number"
            outlined
            prepend-inner-icon="mdi-clock-time-four-outline"
            color="#FBB040"
            label="Thời gian khám"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn @click="onCreate" x-large color="#FBB040" class="text-white" button dark>
            <v-icon right dark class="mr-2"> mdi-folder-plus </v-icon>
            Tạo mới
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { required, email } from "~/utils/validation";

export default {
  name: "PatientDetail",
  data() {
    return {
      rules: {
        required,
        email,
      },
      doctorData: null,
      name: "",
      phone: "",
      slotDuration: "",
    };
  },
  methods: {
    async onCreate() {
      console.log("🚀 ~ onCreate ~ onCreate: ", {
        name: this.name,
        phone: this.phone,
        slotDuration: this.slotDuration,
      })
      await this.$services.appointmentService.createDoctor({
        name: this.name,
        phone: this.phone,
        slotDuration: this.slotDuration,
      });
      this.$router.push({ name: "DoctorList" });
    },
  },
};
</script>
